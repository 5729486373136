import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import { AiOutlineClose } from "react-icons/ai"
import SEO from "./SEO"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import NavigationContext from "../components/navigationContext/NavigationContext"

//TODO refactor navigation component

const Layout = ({ children }) => {
  const navigationContext = useContext(NavigationContext)

  useEffect(() => {
    console.log(window.location.pathname)
  }, [])

  return (
    <div className="w-full h-full">
      {navigationContext.isMenuShowing ? (
        <div className="z-50 w-full h-full fixed bg-black opacity-75  ">
          <AiOutlineClose
            className="text-4xl ml-auto mt-8 mr-8 cursor-pointer animate__animated animate__fadeIn"
            onClick={() => {
              navigationContext.handleSetIsMenuShowing(false)
            }}
          />
          <ul className="flex flex-col text-center pt-64 animate__animated animate__fadeIn">
            <li className="p-5">
              <Link
                to="/"
                className="text-2xl"
                onClick={() => {
                  navigationContext.handleSetIsMenuShowing(false)
                }}
              >
                Home{" "}
              </Link>
            </li>
            <li className="p-5">
              <Link
                to="/About#main"
                className="text-2xl"
                onClick={() => {
                  navigationContext.handleSetIsMenuShowing(false)
                }}
              >
                About{" "}
              </Link>
            </li>
            <li className="p-5">
              <Link
                to="/#contact"
                className="text-2xl"
                onClick={() => {
                  navigationContext.handleSetIsMenuShowing(false)
                }}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      ) : null}

      <SEO />
      <div className="flex flex-col min-h-screen min-w-screen w-full h-full bg-gray-200">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  )
}

export default Layout
