import React, { useState, useEffect, useContext } from "react"
import NavigationContext from "../navigationContext/NavigationContext"
import { Link } from "gatsby"
import backGroundImage from "../../images/hawthorne.jpeg"
import { AiOutlineMenu } from "react-icons/ai"

const Header = props => {
  const [shouldFadeIn, setShouldFadeIn] = useState(false)
  const [shouldFadeInText, setShouldFadeInText] = useState(false)
  const navigationContext = useContext(NavigationContext)
  useEffect(() => {
    setTimeout(() => {
      setShouldFadeIn(true)
    }, 500)
    setTimeout(() => {
      setShouldFadeInText(true)
    }, 900)
  }, [])

  return (
    <header
      className={` w-full bg-no-repeat bg-center bg-cover object-cover relative`}
      style={{ height: "80vh" }}
    >
      {!navigationContext.isMenuShowing &&
        <AiOutlineMenu
          className="absolute z-40 top-0 right-0 text-4xl m-4 sm:m-8 cursor-pointer animate__animated animate__fadeIn"
          onClick={() => {
            navigationContext.handleSetIsMenuShowing(
              !navigationContext.isMenuShowing
            )
          }}
        />}
      <div
        className={`w-full h-full absolute z-10`}
        style={{
          color: "rgba(255, 255, 255, 0.80)",
          backgroundColor: "rgba(42, 47, 74, 0.90)",
        }}
      >
        <div
          className={`transition duration-500 delay-500 ease-in ml-16 ${shouldFadeIn ? "opacity-100" : "opacity-0"
            }`}
        >
          <h1
            className={`pt-32 font-bold text-3xl sm:text-4xl lg:text-6xl `}
          >
            Bridgetown Digital Media
          </h1>
          <hr className="mr-12  sm:mr-16 lg:mr-48"></hr>
          <div className="mt-5 flex-col">
            <p className="sm:inline mb-5 sm:mb-0  mr-8 text-xl md:text-2xl">
              Bridge the gap between technology, and your imagination.
            </p>
            <Link to="#main">
              <button className="p-2 border text-lg mt-5 lg:mt-0  sm:text-lg">
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </div>
      <img
        src={backGroundImage}
        className={`absolute z-0 w-full h-full transition duration-500 ease-in object-cover ${shouldFadeIn ? "opacity-100" : "opacity-0"
          }`}
      ></img>
      {props.children}
    </header>
  )
}

export default Header
