import React from "react"
import { Link } from "gatsby"
import { AiOutlineMail } from "react-icons/ai"
import { AiOutlinePhone } from "react-icons/ai"
import { FiMapPin } from "react-icons/fi"
import { AiFillFacebook } from "react-icons/ai"
import { AiFillTwitterSquare } from "react-icons/ai"
import { AiFillInstagram } from "react-icons/ai"
import { AiFillLinkedin } from "react-icons/ai"

const Footer = () => {
  return (
    <footer
      className="grid grid-auto-rows grid-cols-3"
      style={{ backgroundColor: "rgb(36, 41, 67)" }}
    >
      <div
        style={{ borderColor: "rgba(255, 255, 255, 0.3" }}
        className="col-start-1 col-span-3 sm:col-span-2 row-start-1 row-span-2 sm:row-span-3 border-b"
      >
        <div>
          <h2 className="pt-16 pl-16 text-4xl">Contact Us</h2>
        </div>
        <form
          className="w-full h-full p-16"
          id="contact"
          name="contact"
          method="POST"
          action="https://formspree.io/meqrpzgd"
        >
          <div className="flex items-center justify-around">
            <div className="flex flex-col flex-1 justify-center items-start pr-5">
              <label htmlFor="name">Name: </label>
              <input
                className="w-full flex-1 p-2 "
                style={{ backgroundColor: "rgb(42, 47, 74)" }}
                name="name"
                type="text"
              />
            </div>
            <div className="flex flex-col flex-1 justify-center items-start pl-5">
              <label htmlFor="email">Email:</label>
              <input
                className="w-full flex-1 p-2 "
                style={{ backgroundColor: "rgb(42, 47, 74)" }}
                name="email"
                type="text"
              />
            </div>
          </div>
          <div className="flex items-center justify-center mt-16">
            <div className="flex flex-col justify-center items-start w-full ">
              <label htmlFor="message">Message:</label>
              <textarea
                rows="10"
                cols="800"
                id="message"
                name="message"
                type="text"
                className="w-full"
                style={{ backgroundColor: "rgb(42, 47, 74)" }}
              />
            </div>
          </div>
          <button
            type="submit"
            className="my-16 sm:my-5 border border-solid p-2 rounded-sm transition duration-700 ease-in hover:text-green-400 hover:border-green-400"
          >
            Submit
          </button>
        </form>
      </div>
      <div
        style={{ borderColor: "rgba(255, 255, 255, 0.3" }}
        className="col-start-1 col-span-3 sm:col-start-3 sm:col-span-1 row-start-2 row-span-1 sm:row-start-1 border-t sm:border-l sm:border-t-0 flex items-center justify-start pl-16"
      >
        <AiOutlineMail className="text-4xl inline" />
        <a href='mailto:mark@bridgetowndigitalmedia.com' className="py-5 md:py-0 inline pl-5"> Email Me!</a>
      </div>
      <div
        style={{ borderColor: "rgba(255, 255, 255, 0.3" }}
        className="col-start-1 col-span-3 sm:col-start-3 sm:col-span-1 row-start-3 sm:row-start-2 border-b sm:border-l border-t flex justify-start items-center pl-16"
      >
        <AiOutlinePhone className="text-4xl inline" />
        <p className="py-5 md:py-0 inline pl-5">Phone Number: ‪(541) 708-1346‬</p>
      </div>
      <div
        style={{ borderColor: "rgba(255, 255, 255, 0.3" }}
        className="col-start-1 col-span-3 sm:col-start-3 sm:col-span-1 row-start-4 sm:row-start-3 border-b sm:border-l sm:border-b flex justify-start items-center pl-16"
      >
        <FiMapPin className="text-4xl inline" />

        <p className="py-5 md:py-0 inline pl-5 .break-normal">
          Mailing Address:
          <br /> P.O. Box 1234 <br />
          Portland, Oregon. 97206
        </p> 
      </div>

      <div
        style={{ borderColor: "rgba(255, 255, 255, 0.3" }}
        className="col-start-1 col-span-3 row-start-6 lg:row-start-4 flex justify-end items-center"
      >
        <div className="w-full lg:ml-16 ">
          <p className="text-center lg:text-left pb-5 lg:pb-0">
            <span className="text-lg text-center">©</span><a href='https://www.markfunkhouser.net'>Mark Funkhouser</a>, 2020. All rights
            reserved.
          </p>
        </div>
      </div>

      <div className="col-start-1 col-span-3 lg:col-start-3 lg:col-span-1 row-start-5 md:row-start-4 ">
        <div className="flex items-center justify-around" style={{ minHeight: "100px" }}>
          <a href="https://www.facebook.com/Bridgetown-Digital-Media-105781694614111">
            <AiFillFacebook className="text-5xl m-2" />
          </a>
          <a>

          <AiFillTwitterSquare className="text-5xl m-2" />
          </a>
          <a>
          <AiFillInstagram className="text-5xl m-2" />
          </a>
          <a>
          <AiFillLinkedin className="text-5xl m-2" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
